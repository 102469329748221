import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import {
  CanOrderPayStatusList,
  get,
  getPayOrderIdFromLocalStorage,
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
  i18nFormat,
  removeLocalStoragePayOrderId,
} from '@unionfab/ufc-shop-commons';
import { message } from 'antd';
import cn from 'classnames';

import { getUseShopQuoteStoreByCode, LK_SELECTED_COUPON_CODE } from '@/stores';

import styles from './index.module.less';

export interface ShopQuotePayPalPayBtnProps {
  className?: string;
  style?: Record<string, string | number>;
  orderCode: string;

  onSuccess?: () => void;
  onClosePayOrder?: () => void;
  onCreatePayOrder?: () => void;
}

export const ShopQuotePayPalPayBtn = ({
  className,
  style,

  orderCode,
  onClosePayOrder,
  onCreatePayOrder,
}: ShopQuotePayPalPayBtnProps) => {
  const useShopQuoteStore = getUseShopQuoteStoreByCode(orderCode);
  const { shopOrder, couponCode } = useShopQuoteStore();

  const paypalClientId = window.gConfig.PAYPAL_CLIENT;
  const initialPaypalOptions = {
    'client-id': paypalClientId,
    currency: shopOrder.requirements.quotation?.currency,
  };

  const createPayPalOrderId = async () => {
    const antdMessageKey = 'create_order';

    try {
      message.loading({
        content: i18nFormat('Creating paypal order'),
        key: antdMessageKey,
      });

      const tenantId = getTenantIdFromGConfig();

      const { payPalOrderId, payOrderId } =
        await getUfcShopComposedApi().ufcPaymentApi.createPaypalPayOrder({
          tenantId,
          orderCode,
          options: { type: 'PAYPAL', couponCode },
        });

      typeof onCreatePayOrder == 'function' && onCreatePayOrder();

      useShopQuoteStore.setState({ isWaitingUserPay: true });

      if (!payPalOrderId || !payOrderId) {
        useShopQuoteStore.setState({
          paymentResult: {
            success: false,
            failureMessage:
              'Error creating pay order, refresh and try again, or contact our customer service for help',
          },
        });
        return '';
      }

      return payPalOrderId;
    } catch (e) {
      const paymentResult = {
        success: false,
        failureMessage: '',
      };

      if (get(e as Error, e => e.message) == '订单处于报价中') {
        paymentResult.failureMessage = `Dear Customer, Our Engineers still need to complete your quotation. We will update you once it's completed to finish payment.`;
      } else {
        paymentResult.failureMessage =
          'Error creating pay order, refresh and try again, or contact our customer service for help';
      }

      useShopQuoteStore.setState({ paymentResult });

      return '';
    }
  };

  const _onApprove = async (data: {
    billingToken?: string | null;
    facilitatorAccessToken: string;
    orderID: string;
    payerID?: string | null;
    paymentID?: string | null;
    subscriptionID?: string | null;
    authCode?: string | null;
  }) => {
    try {
      const tenantId = getTenantIdFromGConfig();
      const payOrderId = getPayOrderIdFromLocalStorage(orderCode, tenantId);

      /** 这里 payOrderId 为空代表支付订单已被取消或者异常 */
      if (!payOrderId) return;

      useShopQuoteStore.setState({ isProcessing: true });

      const payOrderInfo =
        await getUfcShopComposedApi().ufcPaymentApi.getPayOrderInfo({
          orderCode,
          tenantId,
          payOrderId,
        });

      if (!payOrderInfo) {
        useShopQuoteStore.setState({
          isProcessing: false,
          paymentResult: {
            success: false,
            failureMessage: 'Payment order not found, please try again later.',
          },
        });

        return;
      } else {
        const { status } = payOrderInfo;
        const isCanApproveStatus = CanOrderPayStatusList.includes(status);

        if (!isCanApproveStatus) {
          useShopQuoteStore.setState({
            isProcessing: false,
            paymentResult: {
              success: false,
              failureMessage: i18nFormat(
                'Payment time expired. Please try again later.',
              ),
            },
          });

          return;
        }
      }

      const paymentRes =
        await getUfcShopComposedApi().ufcPaymentApi.approvePaypalPayOrder({
          tenantId,
          payOrderId,
          orderCode: shopOrder.code,
        });

      if (!paymentRes) {
        useShopQuoteStore.setState({
          isProcessing: false,
          paymentResult: {
            success: false,
            failureMessage:
              'Error completing your payment, please contact our customer service for help.',
          },
        });
      } else {
        localStorage.removeItem(`${LK_SELECTED_COUPON_CODE}_${orderCode}`);

        useShopQuoteStore.setState({
          paymentResult: { success: true, failureMessage: undefined },
        });
      }
    } catch (e) {
      console.log('>>>ShopQuotePayPalPayBtn>>>_onApprove', e);

      useShopQuoteStore.setState({
        isProcessing: false,
        paymentResult: {
          success: false,
          failureMessage: 'An exception occurred. Please try again later.',
        },
      });
    }
  };

  const onCancelPay = async () => {
    const orderCode = shopOrder.code;
    const tenantId = getTenantIdFromGConfig();
    const payOrderId = getPayOrderIdFromLocalStorage(orderCode, tenantId);

    /** 这里 payOrderId 有值代表 payOrder 正处于等待支付阶段 */
    if (payOrderId) {
      const payOrderInfo =
        await getUfcShopComposedApi().ufcPaymentApi.getPayOrderInfo({
          orderCode,
          tenantId,
          payOrderId,
        });

      if (payOrderInfo && CanOrderPayStatusList.includes(payOrderInfo.status)) {
        /** 手动关闭支付订单 */
        await getUfcShopComposedApi().ufcPaymentApi.closePayOrder({
          tenantId,
          orderCode,
          payOrderId,
        });

        typeof onClosePayOrder == 'function' && onClosePayOrder();

        removeLocalStoragePayOrderId(orderCode, tenantId);

        useShopQuoteStore.setState({
          isWaitingUserPay: false,
          paymentResult: {
            success: false,
            failureMessage: i18nFormat(
              'The current pay order seems to be canceled.',
            ),
          },
        });
      }
    }
  };

  return (
    <div
      id="ShopQuotePayPalPayBtn"
      className={cn(className, styles.container)}
      style={style}
    >
      <PayPalScriptProvider deferLoading={false} options={initialPaypalOptions}>
        <PayPalButtons
          className="paypal-button-container"
          style={{ layout: 'vertical' }}
          createOrder={createPayPalOrderId}
          onApprove={_onApprove}
          onCancel={onCancelPay}
        />
      </PayPalScriptProvider>
    </div>
  );
};

ShopQuotePayPalPayBtn.displayName = 'ShopQuotePayPalPayBtn';

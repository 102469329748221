import { isNil, omitBy } from 'lodash';

import * as S from '../../../../schema';
import { LK_SELECTED_COUPON_CODE } from '../../../../stores';
import { AliyunSlsApmTrackerUtils, getTenantIdFromGConfig } from '../../..';
import { BaseAxiosApi } from '../../shared';

export class UfcPaymentApi extends BaseAxiosApi {
  async createPaymentOrderOnPaypal({
    orderId,
    orderCode,
    couponCode,
  }: {
    orderId: string;
    orderCode: string;
    couponCode?: string;
  }) {
    const key = `${LK_SELECTED_COUPON_CODE}_${orderCode}`;
    const couponCodeFromStorage = localStorage.getItem(key);
    const _couponCode = couponCode || couponCodeFromStorage;

    const params: { couponCode?: string | null } = { couponCode: _couponCode };

    if (params.couponCode == "Don't choose coupon") {
      delete params.couponCode;
    }

    const { data } = await this.post<{
      payOrderId: string;
      payPalOrderId: string;
    }>({
      url: `${
        this.apiConfig.apiEndpoint
      }/order/${orderId}/pay/paypal?tenantId=${getTenantIdFromGConfig()}`,
      data: params,
    });

    return data;
  }

  completePaymentOrderOnPaypal = async (
    orderId: string,
    payPalOrderId: string,
    payerId: string,
  ) => {
    if (!orderId || !payPalOrderId || !payerId) {
      return Promise.reject(
        new Error('orderId, payPalOrderId, payerId 不能为空'),
      );
    }

    return await this.post({
      url: `${
        this.apiConfig.apiEndpoint
      }/order/${orderId}/pay/paypal/${payPalOrderId}?tenantId=${getTenantIdFromGConfig()}`,
      data: {
        payerId,
      },
    });
  };

  noticeOceanPayRes = async (o: {
    orderCode: string;
    tenantId: S.Id;
    payOrderId: S.Id;
    xmlData: string;
  }) => {
    // const res = await this.post({
    //   url: `${this.apiConfig.apiEndpoint}/order/pay/notify/oceanpay/notice`,
    //   params: { tenantId, payOrderId },
    //   data: xmlData,
    // });

    const res = await this.post({
      data: o.xmlData,
      params: { tenantId: o.tenantId },
      url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${o.orderCode}/pay_order/${o.payOrderId}/ocean_pay/notice`,
    });

    return 'receive-ok' === ((res as unknown) as string);
  };

  //////////////////////@start v2支付接口/////////////////////////

  /** 创建支付订单 */
  async createPaypalPayOrder({
    options,
    tenantId,
    orderCode,
  }: {
    orderCode: string;
    tenantId: string;
    options: S.CreatePaypalPayOrderParams;
  }) {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: `post-/v2/shop/order/:orderCode/pay_order`,
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${orderCode}/pay_order`,
        request_params: JSON.stringify({
          tenantId,
        }),
      },
      async () => {
        const key = `${LK_SELECTED_COUPON_CODE}_${orderCode}`;
        const couponCodeFromStorage = localStorage.getItem(key);
        const couponCode = S.get(
          options,
          d => d.couponCode,
          couponCodeFromStorage,
        );

        couponCode == "Don't choose coupon" && delete options.couponCode;

        const { data } = await this.post<S.PaypalPayOrder>({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${orderCode}/pay_order`,
          params: { tenantId },
          data: omitBy({ ...options, couponCode }, isNil),
          adapter: (error: any) => {
            const { data } = error;
            if (data.message) {
              return {
                statusCode: error.status, //  接口状态码
                LogCode: 'Order_NOT_FOUND', // 业务状态码
                LogResponse: data.message, // 业务报错信息
              };
            }
            if (data.err.reason) {
              const PayOrderErrorMapping = [
                {
                  code: 'TENANT_OCEAN_PAY_CONFIG_MISSING',
                  reason: 'no ocean pay config for tenant',
                },
                {
                  code: 'INVALID_PAY_ORDER_TYPE',
                  reason: 'illegal PayOrder type',
                },
                {
                  code: 'ORDER_UNDER_QUOTING',
                  reason: 'Order is under quoting',
                },
                {
                  code: 'UNPAYABLE_ORDER_STATUS',
                  reason: 'Unpayable status',
                },
                {
                  code: 'ORDER_ALREADY_PAID',
                  reason: 'Already payed:',
                },
                {
                  code: 'PAYMENT_CANCEL_ERROR',
                  reason: 'error cancelling payment',
                },
                {
                  code: 'PAY_ORDER_NOT_FOUND',
                  reason: 'Unionfab Pay Order not found',
                },
                {
                  code: 'NOT_AN_OCEAN_PAY_ORDER',
                  reason: 'Not a Ocean Pay Order',
                },
                {
                  code: 'UNKNOWN_OCEAN_PAY_DATA_TYPE',
                  reason: 'unknown ocean pay data type',
                },
                {
                  code: 'ORDER_RESPONSE_SIGNATURE_MISMATCH',
                  reason: 'Check Order Response signature mismatch',
                },
                {
                  code: 'ERROR_UPDATING_PAYMENT_TO_EXCEPTION',
                  reason: 'error update payment to exception',
                },
              ];
              const errOne = PayOrderErrorMapping.find(codeObj =>
                data.err.reason.includes(codeObj.reason),
              );
              return {
                statusCode: error.status,
                LogCode: errOne?.code || 'UNKNOWN_CODE',
                LogResponse: data.err.reason || 'UNKNOWN_RESPONSE',
              };
            }
            // 其他错误
            return {
              statusCode: error.status,
              LogCode: 'UNKNOWN_CODE',
              LogResponse: 'UNKNOWN_RESPONSE',
            };
          },
        });

        const payPalOrderId = S.get(data, d => d.payPalOrder.id);
        S.setPayOrderIdToLocalStorage({
          tenantId,
          orderCode,
          payOrderId: data.id,
        });

        return {
          payOrderId: data.id,
          payPalOrderId,
          validSec: data.validSec,
        };
      },
    );
  }

  async createOceanpaymentPayOrder({
    options,
    tenantId,
    orderCode,
  }: {
    orderCode: string;
    tenantId: string;
    options: S.CreateOceanpaymentPayOrderParams;
  }) {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: `post-/v2/shop/order/:orderCode/oceanpay/pay_order`,
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${orderCode}/pay_order`,
        request_params: JSON.stringify({
          tenantId,
        }),
      },
      async () => {
        const key = `${LK_SELECTED_COUPON_CODE}_${orderCode}`;
        const couponCodeFromStorage = localStorage.getItem(key);
        const couponCode = S.get(
          options,
          d => d.couponCode,
          couponCodeFromStorage,
        );

        couponCode == "Don't choose coupon" && delete options.couponCode;

        const { data } = await this.post<S.OceanpaymentPayOrder>({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${orderCode}/pay_order`,
          params: { tenantId },
          data: omitBy({ ...options, couponCode }, isNil),
          adapter: (error: any) => {
            const { data } = error;
            if (data.message) {
              return {
                statusCode: error.status, //  接口状态码
                LogCode: 'Order_NOT_FOUND', // 业务状态码
                LogResponse: data.message, // 业务报错信息
              };
            }
            if (data.err.reason) {
              const PayOrderErrorMapping = [
                {
                  code: 'TENANT_OCEAN_PAY_CONFIG_MISSING',
                  reason: 'no ocean pay config for tenant',
                },
                {
                  code: 'INVALID_PAY_ORDER_TYPE',
                  reason: 'illegal PayOrder type',
                },
                {
                  code: 'ORDER_UNDER_QUOTING',
                  reason: 'Order is under quoting',
                },
                {
                  code: 'UNPAYABLE_ORDER_STATUS',
                  reason: 'Unpayable status',
                },
                {
                  code: 'ORDER_ALREADY_PAID',
                  reason: 'Already payed:',
                },
                {
                  code: 'PAYMENT_CANCEL_ERROR',
                  reason: 'error cancelling payment',
                },
                {
                  code: 'PAY_ORDER_NOT_FOUND',
                  reason: 'Unionfab Pay Order not found',
                },
                {
                  code: 'NOT_AN_OCEAN_PAY_ORDER',
                  reason: 'Not a Ocean Pay Order',
                },
                {
                  code: 'UNKNOWN_OCEAN_PAY_DATA_TYPE',
                  reason: 'unknown ocean pay data type',
                },
                {
                  code: 'ORDER_RESPONSE_SIGNATURE_MISMATCH',
                  reason: 'Check Order Response signature mismatch',
                },
                {
                  code: 'ERROR_UPDATING_PAYMENT_TO_EXCEPTION',
                  reason: 'error update payment to exception',
                },
              ];
              const errOne = PayOrderErrorMapping.find(codeObj =>
                data.err.reason.includes(codeObj.reason),
              );
              return {
                statusCode: error.status,
                LogCode: errOne?.code || 'UNKNOWN_CODE',
                LogResponse: data.err.reason || 'UNKNOWN_RESPONSE',
              };
            }
            // 其他错误
            return {
              statusCode: error.status,
              LogCode: 'UNKNOWN_CODE',
              LogResponse: 'UNKNOWN_RESPONSE',
            };
          },
        });

        S.setPayOrderIdToLocalStorage({
          tenantId,
          orderCode,
          payOrderId: data.id,
        });

        return data;
      },
    );
  }

  /** 获取当前有效的支付订单 */
  async getPayOrderInfo(o: {
    orderCode: string;
    payOrderId: string;
    tenantId: string;
  }) {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: `get-/v2/shop/order/:orderCode/pay_order/:payOrderId`,
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${o.orderCode}/pay_order/${o.payOrderId}`,
        request_params: JSON.stringify({
          tenantId: o.tenantId,
        }),
      },
      async () => {
        const { data } = await this.get<
          S.PaypalPayOrder | S.OceanpaymentPayOrder
        >({
          params: { tenantId: o.tenantId },
          url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${o.orderCode}/pay_order/${o.payOrderId}`,
          adapter: (error: any) => {
            const { data } = error;
            if (data.message) {
              return {
                statusCode: error.status, //  接口状态码
                LogCode: 'Order_NOT_FOUND', // 业务状态码
                LogResponse: data.message, // 业务报错信息
              };
            }
            if (data.err.reason) {
              const GetPayOrderInfoErrorMapping = [
                {
                  code: 'TENANT_OCEAN_PAY_CONFIG_MISSING',
                  reason: 'no ocean pay config for tenant',
                },
                {
                  code: 'INVALID_OPERATION_TYPE',
                  reason: 'invalid op-type',
                },
                {
                  code: 'INVALID_PAY_ORDER_TYPE',
                  reason: 'illegal PayOrder type',
                },
                {
                  code: 'ORDER_APPROVAL_FAILED',
                  reason: 'error approving order',
                },
                {
                  code: 'PAY_ORDER_NOT_FOUND',
                  reason: 'Unionfab Pay Order not found',
                },
                {
                  code: 'NOT_AN_OCEAN_PAY_ORDER',
                  reason: 'Not a Ocean Pay Order',
                },
                {
                  code: 'UNKNOWN_OCEAN_PAY_DATA_TYPE',
                  reason: 'unknown ocean pay data type',
                },
                {
                  code: 'ORDER_RESPONSE_SIGNATURE_MISMATCH',
                  reason: 'Check Order Response signature mismatch',
                },
                {
                  code: 'ERROR_UPDATING_PAYMENT_TO_EXCEPTION',
                  reason: 'error update payment to exception',
                },
              ];
              const errOne = GetPayOrderInfoErrorMapping.find(codeObj =>
                data.err.reason.includes(codeObj.reason),
              );
              return {
                statusCode: error.status,
                LogCode: errOne?.code || 'UNKNOWN_CODE',
                LogResponse: data.err.reason || 'UNKNOWN_RESPONSE',
              };
            }
            // 其他错误
            return {
              statusCode: error.status,
              LogCode: 'UNKNOWN_CODE',
              LogResponse: 'UNKNOWN_RESPONSE',
            };
          },
        });

        return data;
      },
    );
  }

  async approvePaypalPayOrder(o: {
    orderCode: string;
    payOrderId: string;
    tenantId: string;
  }) {
    const { status } = await this.post<{ status: string }>({
      url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${o.orderCode}/pay_order/${o.payOrderId}/pay_pal/on_approve`,
      params: { tenantId: o.tenantId },
    });

    return status == 'ok';
  }

  /** 关闭支付订单 */
  async closePayOrder(o: {
    tenantId: string;
    orderCode: string;
    payOrderId: string;
  }) {
    return AliyunSlsApmTrackerUtils.getAliyunSlsApmTracker().runAndSendApiRecord(
      {
        api_id: `post-/v2/shop/order/:orderCode/pay_order/:payOrderId/cancel`,
        request_url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${o.orderCode}/pay_order/${o.payOrderId}/cancel`,
        request_params: JSON.stringify({
          tenantId: o.tenantId,
        }),
      },
      async () => {
        const { data } = await this.post<{ status: string }>({
          url: `${this.apiConfig.apiEndpoint}/v2/shop/order/${o.orderCode}/pay_order/${o.payOrderId}/cancel`,
          params: { tenantId: o.tenantId },
          adapter: (error: any) => {
            const { data } = error;
            if (data.message) {
              return {
                statusCode: error.status, //  接口状态码
                LogCode: 'Order_NOT_FOUND', // 业务状态码
                LogResponse: data.message, // 业务报错信息
              };
            }
            if (data.err.reason) {
              const CancelPayOrderErrorMapping = [
                {
                  code: 'TENANT_OCEAN_PAY_CONFIG_MISSING',
                  reason: 'no ocean pay config for tenant',
                },
                {
                  code: 'PAY_ORDER_CANCEL_FAILED',
                  reason: 'Cannot cancel Pay Order',
                },
                {
                  code: 'PAYMENT_CANCEL_ERROR',
                  reason: 'error cancelling payment',
                },
                {
                  code: 'INVALID_PAY_ORDER_TYPE',
                  reason: 'illegal PayOrder type',
                },
                {
                  code: 'ORDER_APPROVAL_FAILED',
                  reason: 'error approving order',
                },
                {
                  code: 'INVALID_OPERATION_TYPE',
                  reason: 'invalid op-type',
                },
                {
                  code: 'PAY_ORDER_NOT_FOUND',
                  reason: 'Unionfab Pay Order not found',
                },
                {
                  code: 'NOT_AN_OCEAN_PAY_ORDER',
                  reason: 'Not a Ocean Pay Order',
                },
                {
                  code: 'UNKNOWN_OCEAN_PAY_DATA_TYPE',
                  reason: 'unknown ocean pay data type',
                },
                {
                  code: 'ORDER_RESPONSE_SIGNATURE_MISMATCH',
                  reason: 'Check Order Response signature mismatch',
                },
                {
                  code: 'ERROR_UPDATING_PAYMENT_TO_EXCEPTION',
                  reason: 'error update payment to exception',
                },
              ];
              const errOne = CancelPayOrderErrorMapping.find(codeObj =>
                data.err.reason.includes(codeObj.reason),
              );
              return {
                statusCode: error.status,
                LogCode: errOne?.code || 'UNKNOWN_CODE',
                LogResponse: data.err.reason || 'UNKNOWN_RESPONSE',
              };
            }
            // 其他错误
            return {
              statusCode: error.status,
              LogCode: 'UNKNOWN_CODE',
              LogResponse: 'UNKNOWN_RESPONSE',
            };
          },
        });

        return data;
      },
    );
  }

  //////////////////////@end v2支付接口/////////////////////////
}

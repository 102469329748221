import {
  CanOrderPayStatusList,
  getMaintainerUserInfo,
  getPaymentFailureSolutions,
  getPayOrderIdFromLocalStorage,
  getTenantIdFromGConfig,
  getUfcShopComposedApi,
  i18nFormat,
  isValidArray,
  PaymentChannel,
  removeLocalStoragePayOrderId,
  UfcShopTokenUtils,
} from '@unionfab/ufc-shop-commons';
import { useAsyncEffect, useInterval } from 'ahooks';
import { Button, message, Modal, Space, Spin } from 'antd';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import toObject from 'dayjs/plugin/toObject';
import utc from 'dayjs/plugin/utc';
import { isValidNumber, parsePhoneNumber } from 'libphonenumber-js';
import { useMemo, useState } from 'react';

import CustomerServiceIcon from '@/assets/svgs/customer_service.svg';
import EmailIcon from '@/assets/svgs/email.svg';
import PhoneIcon from '@/assets/svgs/phone.svg';
import { useAppNavigate } from '@/features/shared';
import { getUseShopQuoteStoreByCode } from '@/stores';

import styles from './index.module.less';

dayjs.extend(utc);
dayjs.extend(toObject);
dayjs.extend(timezone);

const WEEK = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
const MONTH = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

// 订单支付相关 Modal 展示
// 1. 钱海支付 3D 验证页面 modal 展示
// 2. 支付过程中提示信息需要以 modal 方式展示
export const ShopQuotePaymentModal = ({
  orderCode,
  paymentChannel,
  onClosePayOrder,
}: {
  orderCode: string;
  paymentChannel: PaymentChannel;
  onClosePayOrder?: () => void;
}) => {
  const useShopOrderStore = getUseShopQuoteStoreByCode(orderCode);
  const {
    shopOrder,
    paymentResult,
    isWaitingUserPay,
    oceanpaymentPayUrl,
    oceanpaymentPayState,
  } = useShopOrderStore(u => ({
    shopOrder: u.shopOrder,
    paymentResult: u.paymentResult,
    isWaitingUserPay: u.isWaitingUserPay,
    oceanpaymentPayUrl: u.oceanpaymentPayUrl,
    oceanpaymentPayState: u.oceanpaymentPayState,
  }));

  const { navToRapidConsulting, navToDealWithPaymentProblem } =
    useAppNavigate();

  const [timeStr, setTimeStr] = useState<string>('');
  const [contactInfo, setContactInfo] = useState<
    Record<'email' | 'phoneNumber', string>
  >({
    email: 'hello@unionfab.com',
    phoneNumber: '+86 182 2160 3447',
  });

  const modalContent = useMemo(() => {
    if (
      paymentChannel &&
      paymentChannel == 'oceanpay' &&
      isWaitingUserPay &&
      !paymentResult
    ) {
      return 'pay-in-progress';
    }

    if (paymentResult && !paymentResult.success) return 'pay-result';

    return null;
  }, [isWaitingUserPay, paymentResult]);

  useInterval(
    () => {
      if (modalContent != null) {
        const currentDate = dayjs().tz('Asia/ShangHai');
        const week = currentDate.get('day');
        const { months, date } = currentDate.toObject();

        setTimeStr(
          `${currentDate.format('HH:mm:ss')}, ${WEEK[week]}, ${
            MONTH[months]
          } ${date}, GMT+8`,
        );
      }
    },
    modalContent != null ? 1 * 1000 : undefined,
  );

  useAsyncEffect(async () => {
    const isAccessTokenValid = UfcShopTokenUtils.isTokenValid();

    try {
      if (!isAccessTokenValid) return;
      const { email, phoneNumber } = await getMaintainerUserInfo();

      let finalPhoneNumber: string = phoneNumber;

      if (!!phoneNumber && isValidNumber(phoneNumber)) {
        finalPhoneNumber = parsePhoneNumber(phoneNumber).formatInternational();
      }

      setContactInfo({ email, phoneNumber: finalPhoneNumber });
    } catch (_e) {
      console.error('>>>HeaderNewsLine', _e);
    }
  }, []);

  const onCancel = () => {
    const doConfirm = async () => {
      if (oceanpaymentPayUrl != null || modalContent === 'pay-in-progress') {
        message.warning(i18nFormat('Payment canceled'));
      }

      useShopOrderStore.setState({
        isWaitingUserPay: false,
        paymentResult: undefined,
        oceanpaymentPayState: undefined,
        oceanpaymentPayUrl: undefined,
      });

      try {
        const orderCode = shopOrder.code;
        const tenantId = getTenantIdFromGConfig();
        const payOrderId = getPayOrderIdFromLocalStorage(orderCode, tenantId);

        if (payOrderId) {
          const payOrderInfo =
            await getUfcShopComposedApi().ufcPaymentApi.getPayOrderInfo({
              orderCode,
              tenantId,
              payOrderId,
            });

          if (
            payOrderInfo &&
            CanOrderPayStatusList.includes(payOrderInfo.status)
          ) {
            await getUfcShopComposedApi().ufcPaymentApi.closePayOrder({
              tenantId,
              orderCode,
              payOrderId,
            });

            typeof onClosePayOrder == 'function' && onClosePayOrder();

            removeLocalStoragePayOrderId(shopOrder.code, tenantId);
          }
        }
      } catch (e) {
        console.log('>>>ShopQuotePaymentModal>>>onCancel>>>error', e);
      }
    };

    if (modalContent == 'pay-in-progress') {
      Modal.confirm({
        onOk: doConfirm,
        title: 'Cancel payment?',
      });
    } else {
      doConfirm();
    }
  };

  const failureDetailInfo = useMemo(() => {
    if (modalContent !== 'pay-result') return <></>;

    const { failureMessage } = paymentResult;
    const match = (failureMessage || '').match(/^(\d+):/);

    let solutions: string[] = [];

    if (match && match[1]) {
      solutions = getPaymentFailureSolutions(Number(match[1]));
    }

    return (
      <div className={styles.paymentFailedInfo}>
        <div className={styles.paymentFailureMessage}>
          {paymentResult.failureMessage}
        </div>
        {paymentResult.failureMessage !== '订单处于报价中' && (
          <>
            <Space direction="vertical">
              <span style={{ fontWeight: 'bold' }}>
                {i18nFormat('Sorry, your order payment failed.')}&nbsp;
                {isValidArray(solutions) &&
                  i18nFormat('Please try the following solutions.')}
              </span>
              {isValidArray(solutions) && (
                <ol
                  style={{
                    paddingLeft: 0,
                    fontWeight: 'bold',
                    listStyleType: 'none',
                  }}
                >
                  {solutions.map((s, i) => (
                    <li key={i}>{i18nFormat(s)}</li>
                  ))}
                </ol>
              )}
            </Space>
            <p>
              <a
                onClick={() => navToDealWithPaymentProblem()}
                style={{ color: '#f89902' }}
              >
                {i18nFormat('Click here to see what to do on payment failure.')}
              </a>
            </p>
          </>
        )}
        <div className={styles.paymentFailureConcatInfo}>
          <span>
            {i18nFormat(
              'If it still cannot solve your problem, you can connect your sales manager right now',
            )}
          </span>
          <div className={styles.contactItemWrapper}>
            <div className={styles.contactItem}>
              <div className={styles.title}>
                {i18nFormat('Contact Sales Manager')}
              </div>
              <div className={styles.info}>
                <EmailIcon />
                <span>{contactInfo.email}</span>
              </div>
              <div className={styles.info}>
                <PhoneIcon />
                <span>{contactInfo.phoneNumber}</span>
              </div>
              <div className={styles.workHours}>
                {i18nFormat('Mon-Fri: 8 am-8 pm, GMT+8')}
              </div>
            </div>
            <div
              className={styles.contactItem}
              style={{ justifyContent: 'space-between' }}
            >
              <div className={styles.title}>
                <span>{i18nFormat('Live Chat')}</span>
                <div className={styles.info} style={{ marginTop: '.25rem' }}>
                  <CustomerServiceIcon />
                  <Button
                    type="primary"
                    id="live-chat"
                    style={{ marginLeft: '.825rem' }}
                    onClick={() => {
                      const twakApi = (window as any).Tawk_API;

                      !!twakApi && twakApi.toggle();
                    }}
                  >
                    <a rel="opener">{i18nFormat('Online Customer Service')}</a>
                  </Button>
                </div>
              </div>

              <div className={styles.cnTime}>
                <Spin spinning={timeStr === '' || typeof timeStr !== 'string'}>
                  {timeStr}
                </Spin>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [paymentResult, modalContent, timeStr, contactInfo]);

  return (
    <Modal
      width={800}
      footer={false}
      onCancel={onCancel}
      open={modalContent != null}
      className={styles.paymentModal}
    >
      {failureDetailInfo}
      {modalContent === 'pay-in-progress' && (
        <div>
          <h2 className={styles.paymentResultWaiting}>
            <div className={styles.paymentResultWaitingText}>
              {i18nFormat('Processing your payment')}
            </div>
            <div className={styles.paymentResultWaitingIcon}>
              <Spin spinning={true} />
            </div>
          </h2>
          {oceanpaymentPayUrl && (
            <p>
              If the verification window is not popped up automatically, try
              click{' '}
              <a
                href={oceanpaymentPayUrl}
                target="_blank"
                style={{ color: '#f89902' }}
                rel="noreferrer"
              >
                here
              </a>{' '}
              to open the window.
            </p>
          )}
          {!oceanpaymentPayState?.detailMessage && (
            <p>
              {i18nFormat(
                'If you accidentally closed the payment window, you can close this popup or refresh the page and resume the payment.',
              )}
            </p>
          )}
          {oceanpaymentPayState?.detailMessage && (
            <p>
              {i18nFormat('The Payment status')}:&nbsp;
              <b>${oceanpaymentPayState?.detailMessage}</b>
            </p>
          )}

          <p>
            Feel free to{' '}
            <a
              onClick={() => navToRapidConsulting(true)}
              style={{ color: '#f89902' }}
            >
              contact our customer service
            </a>{' '}
            for help.
          </p>
        </div>
      )}
    </Modal>
  );
};

/* eslint-disable @typescript-eslint/ban-types */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import qs from 'qs';

import { get } from '../../../schema';
import { RawUfcApiReturnType } from '../../shop-func-apis/common';
import {
  CustomRequestConfig,
  PostRequestConfig,
  ResponseError,
} from './RequestConfig';
import { HttpMethods, UfcShopApiConfig } from './UfcShopApiConfig';

export interface CompatibleRequestOptionsInit extends RequestInit {
  data?: any;
  params?: Record<string, string | number | object | boolean> | object;
  paramsSerializer?: (params: any) => string;
  responseType?: ResponseType;
  useCache?: boolean;
  timeout?: number;
  errorHandler?: (error: ResponseError) => void;
}

export abstract class BaseAxiosApi {
  apiConfig = new UfcShopApiConfig({});

  get agentList() {
    return [this.axiosAgent, this.noauthAgent, this.quickAxiosAgent];
  }

  get tenantId() {
    return this.apiConfig.tenantId;
  }

  get apiEndpoint() {
    return this.apiConfig.apiEndpoint;
  }

  axiosAgent: AxiosInstance;
  noauthAgent: AxiosInstance;
  quickAxiosAgent: AxiosInstance;

  public setApiConfig(apiConfig: UfcShopApiConfig) {
    this.apiConfig = apiConfig;
  }

  constructor(apiConfig: UfcShopApiConfig = new UfcShopApiConfig()) {
    this.setApiConfig(apiConfig);

    // 创建 Axios 实例
    this.axiosAgent =
      this.apiConfig.axiosAgent ||
      axios.create({
        timeout: apiConfig.timeout || 300 * 1000,

        // 参数序列化
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },

        validateStatus: function (status) {
          return status >= 200 && status <= 300;
        },
      });

    this.axiosAgent.interceptors.request.use(this.requestInterceptor as any);

    this.quickAxiosAgent =
      this.apiConfig.quickAxiosAgent ||
      axios.create({
        timeout: 1.5 * 1000,

        // 参数序列化
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },

        validateStatus: function (status) {
          return status >= 200 && status <= 300;
        },
      });

    this.noauthAgent =
      this.apiConfig.quickAxiosAgent ||
      axios.create({
        timeout: 1.5 * 1000,

        // 参数序列化
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },

        validateStatus: function (status) {
          return (status >= 200 && status <= 300) || status === 400;
        },
      });
  }

  private requestInterceptor = (config: AxiosRequestConfig) => {
    // Authorization 等相关头是放在 UfcShopApiConfig 中
    const headers = this.apiConfig.headers || {};

    if (!config.headers) {
      config.headers = {};
    }

    config.headers!.Accept = 'application/json';
    config.headers['Content-Type'] = 'application/json';

    // 针对 Header 进行赋值
    for (const key of Object.keys(headers)) {
      config.headers[key] = headers[key];
    }

    return config;
  };

  async get<RTD, RTE = {}>(
    config: CustomRequestConfig,
    axiosRequestConfig?: AxiosRequestConfig,
  ) {
    return (
      (await this.request<RTD, any, RTE>(
        HttpMethods.GET,
        config,
        axiosRequestConfig,
      )) || ({} as RawUfcApiReturnType<RTD, RTE>)
    );
  }

  async noauthGet<RTD, RTE = {}>(
    config: CustomRequestConfig,
    axiosRequestConfig?: AxiosRequestConfig,
  ) {
    return (
      this.request<RTD>(HttpMethods.GET, config, axiosRequestConfig, {
        noauth: true,
      }) || ({} as RawUfcApiReturnType<RTD>)
    );
  }

  async quickGet<RTD, RTE = {}>(
    config: CustomRequestConfig,
    axiosRequestConfig?: AxiosRequestConfig,
  ) {
    return (
      this.request<RTD>(HttpMethods.GET, config, axiosRequestConfig, {
        quick: true,
      }) || ({} as RawUfcApiReturnType<RTD>)
    );
  }

  async post<RTD = any, RQD = any>(
    config: PostRequestConfig<RQD>,
    axiosRequestConfig?: AxiosRequestConfig,
  ) {
    return (
      this.request<RTD>(HttpMethods.POST, config, axiosRequestConfig) ||
      ({} as RawUfcApiReturnType<RTD>)
    );
  }

  async noauthPost<RTD = any, RQD = any>(
    config: PostRequestConfig<RQD>,
    axiosRequestConfig?: AxiosRequestConfig,
  ) {
    return (
      this.request<RTD>(HttpMethods.POST, config, axiosRequestConfig, {
        noauth: true,
      }) || ({} as RawUfcApiReturnType<RTD>)
    );
  }

  async delete<RTD>(
    config: CustomRequestConfig,
    axiosRequestConfig?: AxiosRequestConfig,
  ) {
    return this.request<RTD>(HttpMethods.DELETE, config, axiosRequestConfig);
  }

  async put<RTD = any, RQD = any>(
    config: PostRequestConfig<RQD>,
    axiosRequestConfig?: AxiosRequestConfig,
  ) {
    return this.request<RTD>(HttpMethods.PUT, config, axiosRequestConfig);
  }

  async patch<RTD = any>(
    config: PostRequestConfig<RTD>,
    axiosRequestConfig?: AxiosRequestConfig,
  ) {
    return this.request<RTD>(HttpMethods.PATCH, config, axiosRequestConfig);
  }

  async request<RTD, RQD = any, RTE = {}>(
    method: HttpMethods,
    config: CustomRequestConfig | PostRequestConfig<RQD>,
    axiosRequestConfig: AxiosRequestConfig = {},
    { quick = false, noauth = false } = {},
  ): Promise<RawUfcApiReturnType<RTD, RTE>> {
    const finalAxiosRequestConfig: AxiosRequestConfig = {
      method: method,
      url: config.url,
      params: config.params || {},
      paramsSerializer: config.paramsSerializer,
      ...axiosRequestConfig,
    };
    if (
      method === HttpMethods.POST ||
      method === HttpMethods.PUT ||
      method === HttpMethods.PATCH
    ) {
      finalAxiosRequestConfig.data =
        (config as PostRequestConfig<RTD>).data || {};
    }

    try {
      const result = await (noauth
        ? this.noauthAgent
        : quick
        ? this.quickAxiosAgent
        : this.axiosAgent
      ).request<RawUfcApiReturnType<RTD, RTE>>(finalAxiosRequestConfig);

      return result.data as RawUfcApiReturnType<RTD, RTE>;
    } catch (error: any) {
      // 优惠码搜索不存在时，接口报错不抛出异常
      const errorCodeArr = [
        'REDEEMED',
        'PROMOTION_REDEEMED',
        'NOT_FOUND',
        'EXPIRED',
      ];
      if (
        // error?.response?.data?.err?.uri == '/order/coupon/redeem' &&
        errorCodeArr.includes(error?.response?.data?.err?.code)
      ) {
        throw error?.response?.data?.err;
      }
      // const response = get(error, (error: any) => error.response);
      // throw response;

      /** error 示例
       * axios.get('/user/12345')
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
       */
      // 这里对于 Error 对象重新封装，提取出 message 与 code
      delete (error as any).config;

      // 如果 adapter 存在，则调用 adapter 函数
      if (typeof (config as CustomRequestConfig).adapter === 'function') {
        const errorObj = (config as CustomRequestConfig).adapter(
          error.response,
        );
        throw errorObj;
      }

      const errorObj = new ResponseError({
        code: get(
          error,
          (error: any) => error.response.data.err.code,
          (error as Error).name,
        ),
        message: get(
          error,
          (error: any) => error.response.data.err.reason,
          (error as Error).message,
        ),

        statusCode: get(error, (error: any) => error.response.status),
        exceptionId: get(
          error,
          (error: any) => error.response.data.err.exceptionId,
        ),
        exception: get(
          error,
          (error: any) => error.response.data.err.exception,
        ),
      });

      if (this.apiConfig.onError) {
        this.apiConfig.onError({
          requestConfig: config,
          axiosRequestConfig,
          error: errorObj,
          rawError: error as Error,
        });
      }

      throw errorObj;
    }
  }
}

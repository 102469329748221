import { Perfume } from '@unionfab/perfume.js';
import SlsTracker from '@unionfab/web-track-browser';
import dayjs from 'dayjs';
import { v4 as uuidV4 } from 'uuid';

import {
  detectOS,
  genId,
  get,
  isMobile,
  ShopAccountInfo,
} from '../../../schema';
import { getUfcShopRuntimeEnv } from '../../../singleton-ins';
import { UfcShopTokenUtils } from '../../../skeleton/env/ufc-shop-token-store';
import { UfcShopApiConfig, UfcShopComposedApi } from '../../shop-class-apis';
import { ClientGeoInfoApi } from '../geo/ClientGeoInfoApi';
import { TrackerUtils } from '../shared/TrackerUtils';
import { getMetricsFromWindowPerformance } from './page_performance';
import { ApmApiTrackRecord, ApmEventTrackRecord, ApmEventType } from './types';
import { ApmBaseTrackRecord } from './types/ApmBaseTrackRecord';
import {
  ApmPageStayTrackRecord,
  ApmPageTrackRecord,
} from './types/ApmPageTrackRecord';
import { AliyunSlsApiCodeMapping } from './AliyunSlsApiCodeUtils';

let defaultAliyunSlsApmTracker: AliyunSlsApmTracker;

const pageMetrics = {
  ttfb: undefined,
  firstContentfulPaint: undefined,
  largestContentfulPaint: undefined,
};

function initPerformanceMonitor() {
  if (typeof window === 'undefined') {
    return;
  }

  // 默认从 performance 中计算

  new Perfume({
    analyticsTracker: options => {
      const { metricName, data } = options;

      switch (metricName) {
        case 'navigationTiming':
          if (data && (data as any)['timeToFirstByte']) {
            pageMetrics.ttfb = (data as any)['timeToFirstByte'];
          }
          break;
        case 'FCP':
          pageMetrics.firstContentfulPaint = data as number;
          break;
        case 'LCP':
          pageMetrics.largestContentfulPaint = data as number;
          break;
      }
    },
  });
}

initPerformanceMonitor();

export const AliyunSlsApmTrackerUtils = {
  getAliyunSlsApmTracker: () => {
    if (!defaultAliyunSlsApmTracker) {
      defaultAliyunSlsApmTracker = new AliyunSlsApmTracker();
    }

    return defaultAliyunSlsApmTracker;
  },
};

export class AliyunSlsApmTracker {
  slsPageTracker: SlsTracker;
  slsPageStayTracker: SlsTracker;
  slsApiTracker: SlsTracker;
  slsEventTracker: SlsTracker;

  pageRenderDuration?: number;

  lastPageRecord?: Partial<ApmPageTrackRecord>;
  currentPageStayRecord: Partial<ApmPageStayTrackRecord>;

  stayInterval?: NodeJS.Timeout;

  constructor() {
    // 判断是否存在 uoms_session_id，不存在则创建
    let storedSessionId = localStorage.getItem('uoms_session_id');

    if (!storedSessionId) {
      storedSessionId = genId();
      localStorage.setItem('uoms_session_id', storedSessionId);
    }

    this.slsPageTracker = new SlsTracker({
      host: 'ap-southeast-1.log.aliyuncs.com', // 所在地域的服务入口。例如cn-hangzhou.log.aliyuncs.com
      project: 'unionfab-site-arms', // Project名称。
      logstore: 'unionfab-oversea-landing-page-track', // Logstore名称。
      time: 2.5, // 发送日志的时间间隔，默认是 10 秒。
      count: 5, // 发送日志的数量大小，默认是 10 条。
      topic: 'page-track', // 自定义日志主题。
      source: window.location.hostname,
    });

    this.slsPageStayTracker = new SlsTracker({
      host: 'ap-southeast-1.log.aliyuncs.com', // 所在地域的服务入口。例如cn-hangzhou.log.aliyuncs.com
      project: 'unionfab-site-arms', // Project名称。
      logstore: 'unionfab-oversea-landing-page-stay-track', // Logstore名称。
      time: 5, // 发送日志的时间间隔，默认是 10 秒。
      count: 5, // 发送日志的数量大小，默认是 10 条。
      topic: 'page-stay-track', // 自定义日志主题。
      source: window.location.hostname,
    });

    this.slsApiTracker = new SlsTracker({
      host: 'ap-southeast-1.log.aliyuncs.com', // 所在地域的服务入口。例如cn-hangzhou.log.aliyuncs.com
      project: 'unionfab-site-arms', // Project名称。
      logstore: 'unionfab-oversea-shop-api-track', // Logstore名称。
      time: 5, // 发送日志的时间间隔，默认是 10 秒。
      count: 5, // 发送日志的数量大小，默认是 10 条。
      topic: 'api-track', // 自定义日志主题。
      source: window.location.hostname,
    });

    this.slsEventTracker = new SlsTracker({
      host: 'ap-southeast-1.log.aliyuncs.com', // 所在地域的服务入口。例如cn-hangzhou.log.aliyuncs.com
      project: 'unionfab-site-arms', // Project名称。
      logstore: 'unionfab-oversea-shop-event-track', // Logstore名称。
      time: 5, // 发送日志的时间间隔，默认是 10 秒。
      count: 5, // 发送日志的数量大小，默认是 10 条。
      topic: 'event-track', // 自定义日志主题。
      source: window.location.hostname,
    });

    // 每 5 秒上报一次
    this.stayInterval = setInterval(this.onStayIntervalMonitor, 5 * 1000);

    if (!pageMetrics.firstContentfulPaint) {
      pageMetrics.firstContentfulPaint = getMetricsFromWindowPerformance().firstContentfulPaint;
    }

    if (!pageMetrics.ttfb) {
      pageMetrics.ttfb = getMetricsFromWindowPerformance().ttfb;
    }
  }

  onStayIntervalMonitor = async () => {
    if (!this.lastPageRecord) {
      return;
    }

    const now = dayjs();

    if (!this.currentPageStayRecord) {
      this.currentPageStayRecord = {
        id: uuidV4(),
        page_session_id: this.lastPageRecord.page_session_id,
        page_url: this.lastPageRecord.page_url,
        stay_seconds: 1,
        created_at: now.format(),
        created_at_unix_timestamp: now.unix(),
      };
    } else {
      this.currentPageStayRecord.stay_seconds += 5;
    }

    // 过滤异常数据，大于 5min 的不进行上报
    if (this.currentPageStayRecord.stay_seconds < 5 * 60) {
      const eventRecord = this.currentPageStayRecord;

      await this.setupBaseTrackInfo(eventRecord);

      this.slsPageStayTracker.send(this.currentPageStayRecord);
    }
  };

  longTermEventMap: Partial<
    Record<ApmEventType, Partial<ApmEventTrackRecord>>
  > = {};

  onEventStart = async (eventTrackRecord: Partial<ApmEventTrackRecord>) => {
    const startAt = dayjs();
    eventTrackRecord.start_at = startAt.format();
    eventTrackRecord.start_at_unix_timestamp_milliseconds = startAt.valueOf();

    this.longTermEventMap[eventTrackRecord.event_type] = eventTrackRecord;
  };

  onEventFinish = async (finishRecord: Partial<ApmEventTrackRecord>) => {
    const startRecord = this.longTermEventMap[finishRecord.event_type];
    if (!startRecord) {
      return;
    }
    this.longTermEventMap[finishRecord.event_type] = undefined;

    // 补全并上报
    const finishAt = dayjs();
    startRecord.finish_at = finishAt.format();
    startRecord.finish_at_unix_timestamp_milliseconds = finishAt.valueOf();
    startRecord.elapse_milliseconds =
      startRecord.finish_at_unix_timestamp_milliseconds -
      startRecord.start_at_unix_timestamp_milliseconds;

    this.sendEventRecord(startRecord);
  };

  shopAccountInfo: ShopAccountInfo;

  /** 发送设备追踪日志 */
  sendPageRecord = async (pageRecord: Partial<ApmPageTrackRecord>) => {
    pageRecord.id = dayjs().format('YYYYMMDDHHmm') + '-' + uuidV4();
    pageRecord.page_session_id = pageRecord.id;
    pageRecord.user_session_id = TrackerUtils.getUserSessionId();
    if (!this.lastPageRecord) {
      pageRecord.page_refresh_type = 'MPA';
    } else {
      pageRecord.page_refresh_type = 'SPA';
    }

    pageRecord.hostname = window.location.hostname;
    pageRecord.page_url = window.location.href;
    pageRecord.unionfab_user_origin = window.localStorage.getItem('origin')!;

    const now = dayjs();
    pageRecord.created_at = now.format();
    pageRecord.created_at_unix_timestamp = now.unix();

    if (pageMetrics.ttfb) {
      pageRecord.metrics_ttfb = pageMetrics.ttfb;
    }

    if (pageMetrics.firstContentfulPaint) {
      pageRecord.metrics_fcp = pageMetrics.firstContentfulPaint;
    }

    if (pageMetrics.largestContentfulPaint) {
      pageRecord.metrics_lcp = pageMetrics.largestContentfulPaint;
    }

    if (
      (pageRecord.metrics_page_render_duration || this.pageRenderDuration) &&
      pageMetrics.firstContentfulPaint
    ) {
      pageRecord.metrics_tti =
        (pageRecord.metrics_page_render_duration || this.pageRenderDuration) +
        pageMetrics.firstContentfulPaint;
    }

    await this.setupBaseTrackInfo(pageRecord);

    this.slsPageTracker.send(pageRecord);

    if (this.lastPageRecord) {
      pageRecord.page_refresh_type = 'MPA';
    } else {
      pageRecord.page_refresh_type = 'SPA';
    }

    this.lastPageRecord = pageRecord;

    this.currentPageStayRecord = undefined;

    // 触发一次暂停上报
    this.onStayIntervalMonitor();
  };

  sendEventRecord = async (eventRecord: Partial<ApmEventTrackRecord>) => {
    eventRecord.id = uuidV4();

    await this.setupBaseTrackInfo(eventRecord);

    this.slsEventTracker.send(eventRecord);
  };

  sendApiRecord = async (apiRecord: Partial<ApmApiTrackRecord>) => {
    apiRecord.id = uuidV4();

    await this.setupBaseTrackInfo(apiRecord);

    this.slsApiTracker.send(apiRecord);
  };

  runAndSendApiRecord = async <R>(
    initialApiRecord: Partial<ApmApiTrackRecord>,
    func: () => Promise<R>,
  ): Promise<R> => {
    const startAt = dayjs();
    let apiRecord: Partial<ApmApiTrackRecord> = {
      ...initialApiRecord,
      start_at: startAt.format(),
      start_at_unix_timestamp_milliseconds: startAt.valueOf(),
    };

    try {
      const resp = await func();
      apiRecord.response_status = 'success';
      apiRecord.response_status_code = 200;
      return resp;
    } catch (e) {
      apiRecord.response_status = 'error';
      apiRecord.response_status_code = get(
        e,
        (error: any) => error.response.statusCode,
        (e as Error).name,
      );
      apiRecord.log_code = get(e, (error: any) => error.LogCode);
      apiRecord.log_response = get(e, (error: any) => error.LogResponse);
      console.log('apiRecord=======', apiRecord);

      throw e;
    } finally {
      const finishAt = dayjs();

      apiRecord = {
        ...apiRecord,
        finish_at: finishAt.format(),
        finish_at_unix_timestamp_milliseconds: finishAt.valueOf(),
        elapse_milliseconds: finishAt.diff(startAt, 'milliseconds'),
      };

      this.sendApiRecord(apiRecord);
    }
  };

  // 构造包含错误码日志并上报
  runGenerateLogAndSendApiRecord = async <R>(
    initialApiRecord: Partial<ApmApiTrackRecord>,
    func: () => Promise<R extends { response?: any } ? R : never>,
  ): Promise<R extends { response?: any } ? R : never> => {
    const startAt = dayjs();
    let apiRecord: Partial<ApmApiTrackRecord> = {
      ...initialApiRecord,
      start_at: startAt.format(),
      start_at_unix_timestamp_milliseconds: startAt.valueOf(),
    };

    try {
      const resp = await func();
      apiRecord.response_status = 'success';
      apiRecord.response_status_code = 200;
      apiRecord.log_code = AliyunSlsApiCodeMapping[apiRecord.api_id]
        ? AliyunSlsApiCodeMapping[apiRecord.api_id].getCode(resp)
        : 'UNKNOWN';
      apiRecord.log_response = JSON.stringify(resp.response);
      return resp;
    } catch (e) {
      apiRecord.response_status = 'error';
      apiRecord.response_status_code = get(
        e,
        (error: any) => error.response.data.err.code,
        (e as Error).name,
      );
      // TODO
      apiRecord.log_code = 'API_ERROR';
      apiRecord.log_response = JSON.stringify(e);

      throw e;
    } finally {
      const finishAt = dayjs();

      apiRecord = {
        ...apiRecord,
        finish_at: finishAt.format(),
        finish_at_unix_timestamp_milliseconds: finishAt.valueOf(),
        elapse_milliseconds: finishAt.diff(startAt, 'milliseconds'),
      };

      this.sendApiRecord(apiRecord);
    }
  };

  private async setupBaseTrackInfo(record: Partial<ApmBaseTrackRecord>) {
    if (this.lastPageRecord && !record.page_session_id) {
      record.page_session_id = this.lastPageRecord.page_session_id;
      record.user_session_id = this.lastPageRecord.user_session_id;
      record.unionfab_person_id = this.lastPageRecord.unionfab_person_id;
      record.unionfab_customer_id = this.lastPageRecord.unionfab_customer_id;
    }

    if (!record.user_session_id) {
      record.user_session_id = TrackerUtils.getUserSessionId();
    }

    if (typeof process !== 'undefined') {
      record.unionfab_env_tag = process.env.NEXT_PUBLIC_TAG!;
      record.unionfab_env_server_region =
        process.env.NEXT_PUBLIC_DEPLOY_MACHINE! || window.serverIpEnv?.region;
    }

    let geoInfo = ClientGeoInfoApi.getIns().geoInfo;

    if (!geoInfo.country) {
      await ClientGeoInfoApi.getIns().init();
      geoInfo = ClientGeoInfoApi.getIns().geoInfo;
    }

    const personId = UfcShopTokenUtils.getPersonIdFromAccessTokenOrIdToken();

    if (personId) {
      record.unionfab_person_id = personId.replace(
        // 移除 Person 前缀
        'PERSON-',
        '',
      );

      // 尝试获取 ShopAccountInfo
      if (this.shopAccountInfo === undefined) {
        try {
          // 这里的上报不使用全局的 ufcShopComposedApi，避免与全局的关联
          const dtcApi = new UfcShopComposedApi(
            new UfcShopApiConfig({
              apiEndpoint: getUfcShopRuntimeEnv().apiEndpoint,
              headers: {
                Authorization: `Bearer ${UfcShopTokenUtils.getAccessToken()}`,
              },
            }),
          );
          this.shopAccountInfo = await dtcApi.userApi.getShopAccountInfo();
        } catch (e) {
          console.error(e);
          this.shopAccountInfo = null;
        }
      }

      if (this.shopAccountInfo) {
        record.unionfab_customer_id = this.shopAccountInfo.accountSetup.defaultCustomerId;
      }
    } else {
      record.unionfab_person_id = ``;
    }

    record.user_ip = geoInfo.ip;
    record.user_country = geoInfo.country;
    record.user_city = geoInfo.city;
    record.navigator_language = window.navigator.language;
    record.navigator_platform = window.navigator.platform;
    record.navigator_user_agent = window.navigator.userAgent;
    record.user_device_type = isMobile() ? 'mobile' : 'pc';
    record.user_os = detectOS();
  }
}

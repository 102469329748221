import { PageHeader } from '@ant-design/pro-components';
import * as S from '@unionfab/ufc-shop-commons';
import { getUfcShopComposedApi, i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, Space, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import LeftCircleIcon from '@/assets/svgs/LeftCircle.svg';
import { Ellipsis } from '@/commons/components/Element/ellipsis';
import {
  useOrderDetailsStore,
  useOrderListStore,
  useQuoteListStore,
} from '@/stores';

import { OrderCheckoutButton } from '../../components/OrderCheckoutButton';
import { OrderCloseButton } from '../../components/OrderCloseButton';
import { OrderQualityInspectionButton } from '../../components/OrderQualityInspectionButton';
import { OrderInvoiceBtn } from '../../components/OrdersTable/OrderInvoiceBtn';
import { getNoteText } from './noteText';
import styles from './OrderDetails.module.less';
import { OrderDetailPanel } from './OrderInfoTable/OrderDetailPanel';
import { OrderEstDeliveryAndSteps } from './OrderInfoTable/OrderEstDeliveryAndSteps';
import { ShopOrderItemReviewTable } from './OrderInfoTable/ShopOrderItemReviewTable';

export const OrderDetail = ({ title }: { title: string }) => {
  const orderCode = useParams().orderCode;

  const { storeShopOrder, loadShopOrder, shopQuotation, loadingShopOrder } =
    useOrderDetailsStore(d => ({
      storeShopOrder: d.shopOrder,
      loadShopOrder: d.loadShopOrder,
      shopQuotation: d.shopQuotation,
      loadingShopOrder: d.loadingShopOrder,
    }));

  const navigate = useNavigate();

  useEffect(() => {
    if (orderCode) {
      loadShopOrder(orderCode);
    }
  }, [orderCode]);

  const { data: shopPriceInfo } = useQuery(
    ['priceInfo', storeShopOrder],
    () => {
      if (
        !storeShopOrder ||
        (storeShopOrder &&
          !['WAIT_SUBMIT', 'WAIT_REVIEW', 'WAIT_CUSTOM_CONFIRM'].includes(
            storeShopOrder.status,
          ))
      )
        return;

      const expressId = S.get(
        storeShopOrder,
        o => o.requirements.customerSelections.delivery.expressId,
      );

      return getUfcShopComposedApi().shopOrderQueryApi.calcPrice(
        storeShopOrder.code,
        {
          expressId,
        },
      );
    },
  );

  const shopOrder =
    storeShopOrder?.code === orderCode ? storeShopOrder : undefined;

  const shopOrderStatus = shopOrder?.status;

  if (!orderCode) {
    return <></>;
  }

  const OrderPageHeader = useMemo(() => {
    const btns = (
      <Space>
        <OrderInvoiceBtn order={shopOrder} buttonType="primary" />
        <OrderQualityInspectionButton shopOrder={shopOrder} />
        <OrderCheckoutButton
          code={orderCode}
          status={shopOrderStatus}
          hasAddress={S.get(shopOrder, o => o.delivery.items, []).length > 0}
        />
      </Space>
    );

    return (
      <PageHeader
        title={i18nFormat('Back to List')}
        className={styles.pageHeader}
        onBack={() => navigate(-1)}
        backIcon={<LeftCircleIcon />}
      >
        <div className={styles.spaceBetween}>
          <div className={styles.orderCode}>
            <span>{title}</span>
            <span>#{orderCode}</span>
          </div>
          {btns}
        </div>
      </PageHeader>
    );
  }, [orderCode, shopOrderStatus]);

  const quotationInsuredStr = useMemo(() => {
    if (!shopOrderStatus || !S.get(shopPriceInfo, p => p.insuredUntil))
      return <></>;

    const tz = dayjs.tz.guess();

    let formatTime = S.get(shopPriceInfo, p => p.insuredUntil);

    tz && (formatTime = dayjs(formatTime).tz(tz).format('MM/DD/YYYY HH:mm:ss'));

    const content = (
      <>
        {i18nFormat('After')}&nbsp;{formatTime}&nbsp;,
        {i18nFormat(
          'The total price maybe change, please notice the latest price',
        )}
      </>
    );

    return (
      <Typography.Paragraph
        ellipsis={{ rows: 1, tooltip: content }}
        className={styles.quotationInsuredStr}
      >
        {content}
      </Typography.Paragraph>
    );
  }, [shopOrderStatus, shopPriceInfo]);

  return (
    <div className={styles.detailContainer}>
      {OrderPageHeader}
      <div className={styles.detailContent}>
        <Spin spinning={loadingShopOrder}>
          {getNoteText(shopOrderStatus) !== '' && (
            <Ellipsis className={styles.noteText} maxWidth="100%">
              {getNoteText(shopOrderStatus)}
            </Ellipsis>
          )}
          <div className={styles.status}>
            {i18nFormat('Status')}:{' '}
            {i18nFormat(
              S.getOrderShopStatusByInquiryOrderStatus(shopOrderStatus),
            )}
            {quotationInsuredStr}
          </div>
          <OrderEstDeliveryAndSteps />
          <OrderDetailPanel
            shopPrice={shopPriceInfo}
            className={styles.detailPanel}
            orderCode={orderCode}
          />
          <ShopOrderItemReviewTable
            className={styles.reviewTable}
            order={shopOrder}
            priceInfo={shopPriceInfo}
            quotation={shopQuotation}
          />
        </Spin>
      </div>

      {shopOrder &&
        (shopOrderStatus == 'WAIT_SUBMIT' ||
          shopOrderStatus == 'WAIT_REVIEW' ||
          shopOrderStatus == 'WAIT_CUSTOM_CONFIRM') && (
          <div className={styles.closeBtn}>
            <OrderCloseButton
              buttonType="primary"
              order={shopOrder}
              onClose={() => {
                loadShopOrder(shopOrder.code);
                /** 这里清空列表页订单数据防止返回列表页时调用订单价格计算接口报错（已关闭订单无法计算）导致的 404 */
                useOrderListStore.setState({ orderList: [] });
                useQuoteListStore.setState({ orderList: [] });
                /** 关闭订单后返回上一级路由 */
                navigate(-1);
              }}
            />
          </div>
        )}
    </div>
  );
};

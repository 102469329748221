export const AliyunSlsApiCodeMapping = {
  /** 营销页 */
  /** 账号密码登录 */
  'post-/v2/shop/auth/login': {
    getCode: res => {
      if (res.type && res.type === 'system.exception/unauthorized') {
        return 'EMAIL_PASSWORD_INCORRECT';
      }
      return res.code;
    },
  },
  /** 检查邮箱是否注册 */
  'get-/auth/registry_check': {
    getCode: res => {
      return res.code;
    },
  },
  /** 发送邮箱验证码 */
  'post-/v2/shop/auth/sign_up/send_verify_email': {
    getCode: res => {
      return res.code || 'USER_EMAIL_VERIFY_FAIL';
    },
  },
  /** 注册 */
  'post-/v2/shop/auth/sign_up': {
    getCode: res => {
      return res.code || 'USER_EMAIL_SIGNUP_FAIL';
    },
  },
  /** 谷歌登录 */
  'post-/v2/shop/auth/google_login': {
    getCode: res => {
      return res.code || 'GOOGLE_LOGIN_FAIL';
    },
  },

  /** 下单页 */
  /** 创建/修改订单 */
  'post-/v2/shop/order': {
    getCode: error => {
      const { data } = error;
      if (data.status === 'error') {
        // 修改时可能出现的异常
        if (data.err.code === 'cannot_update_order_under_given_status') {
          return 'CANNOT_UPDATE_ORDER_UNDER_STATUS';
        }
        return 'INVALID_OPERATION_TYPE';
      }
      if (data.code) {
        return 'ANONYMOUS_SESSION_LIMIT_EXCEEDED';
      }
      return 'SUCCESS';
    },
  },
  /** 计算价格 */
  'get-/v2/shop/order/:orderCode/calc_price': {
    getCode: error => {
      const { data } = error;
      return data.code;
    },
  },
  /** 报价 */
  'get-/v2/shop/order/:orderCode/quotation': {
    getCode: error => {
      const { data } = error;
      // 正常情况
      if (data.status === 'ok') {
        return 'SUCCESS';
      }
      // 假定返回包含reason的统一归类为返回异常
      if (data.reason) {
        if (!data.code) {
          return 'COUPON_NOT_FOUND';
        }
        if (data.reason.includes('cannot calc-quotation')) {
          return 'COUPON_REDEEM_FAILED';
        }
        if (data.reason.includes('cannot persistent quotation')) {
          return 'QUOTE_PERSIST_FAIL_INVALID_STATUS';
        }
      }
      // 其他情况
      return 'UNKNOWN_ERROR';
    },
  },
  /** 支付订单 */
  'post-/v2/shop/order/:orderCode/pay_order': {
    getCode: error => {
      const { data } = error;
      // 正常情况
      if (data.status === 'ok') {
        return 'SUCCESS';
      }
      // 异常
      const errOne = PayOrderErrorCodes.find(code =>
        data.reason.includes(code.reason),
      );
      if (errOne) {
        return errOne.code;
      }
      // 未知错误
      return 'UNKNOWN_ERROR';
    },
  },
  /** 获取支付订单 */
  'get-/v2/shop/order/:orderCode/pay_order/:payOrderId': {
    getCode: error => {
      const { data } = error;
      // 正常情况
      if (data.status === 'ok') {
        return 'SUCCESS';
      }
      // 异常
      const errOne = GetPayOrderInfoErrorCodes.find(code =>
        data.reason.includes(code.reason),
      );
      if (errOne) {
        return errOne.code;
      }
      // 未知错误
      return 'UNKNOWN_ERROR';
    },
  },
  // 取消支付订单
  'post-/v2/shop/order/:orderCode/pay_order/:payOrderId/cancel': {
    getCode: error => {
      const { data } = error;
      // 正常情况
      if (data.status === 'ok') {
        return 'SUCCESS';
      }
      // 异常
      const errOne = CancelPayOrderErrorCodes.find(code =>
        data.reason.includes(code.reason),
      );
      if (errOne) {
        return errOne.code;
      }
      // 未知错误
      return 'UNKNOWN_ERROR';
    },
  },
};

const PayOrderErrorCodes = [
  {
    code: 'TENANT_OCEAN_PAY_CONFIG_MISSING',
    reason: 'no ocean pay config for tenant',
  },
  {
    code: 'INVALID_PAY_ORDER_TYPE',
    reason: 'illegal PayOrder type',
  },
  {
    code: 'ORDER_UNDER_QUOTING',
    reason: 'Order is under quoting',
  },
  {
    code: 'UNPAYABLE_ORDER_STATUS',
    reason: 'Unpayable status',
  },
  {
    code: 'ORDER_ALREADY_PAID',
    reason: 'Already payed:',
  },
  {
    code: 'PAYMENT_CANCEL_ERROR',
    reason: 'error cancelling payment',
  },
  {
    code: 'PAY_ORDER_NOT_FOUND',
    reason: 'Unionfab Pay Order not found',
  },
  {
    code: 'NOT_AN_OCEAN_PAY_ORDER',
    reason: 'Not a Ocean Pay Order',
  },
  {
    code: 'UNKNOWN_OCEAN_PAY_DATA_TYPE',
    reason: 'unknown ocean pay data type',
  },
  {
    code: 'ORDER_RESPONSE_SIGNATURE_MISMATCH',
    reason: 'Check Order Response signature mismatch',
  },
  {
    code: 'ERROR_UPDATING_PAYMENT_TO_EXCEPTION',
    reason: 'error update payment to exception',
  },
];

const GetPayOrderInfoErrorCodes = [
  {
    code: 'TENANT_OCEAN_PAY_CONFIG_MISSING',
    reason: 'no ocean pay config for tenant',
  },
  {
    code: 'INVALID_OPERATION_TYPE',
    reason: 'invalid op-type',
  },
  {
    code: 'INVALID_PAY_ORDER_TYPE',
    reason: 'illegal PayOrder type',
  },
  {
    code: 'ORDER_APPROVAL_FAILED',
    reason: 'error approving order',
  },
  {
    code: 'PAY_ORDER_NOT_FOUND',
    reason: 'Unionfab Pay Order not found',
  },
  {
    code: 'NOT_AN_OCEAN_PAY_ORDER',
    reason: 'Not a Ocean Pay Order',
  },
  {
    code: 'UNKNOWN_OCEAN_PAY_DATA_TYPE',
    reason: 'unknown ocean pay data type',
  },
  {
    code: 'ORDER_RESPONSE_SIGNATURE_MISMATCH',
    reason: 'Check Order Response signature mismatch',
  },
  {
    code: 'ERROR_UPDATING_PAYMENT_TO_EXCEPTION',
    reason: 'error update payment to exception',
  },
];

const CancelPayOrderErrorCodes = [
  {
    code: 'TENANT_OCEAN_PAY_CONFIG_MISSING',
    reason: 'no ocean pay config for tenant',
  },
  {
    code: 'PAY_ORDER_CANCEL_FAILED',
    reason: 'Cannot cancel Pay Order',
  },
  {
    code: 'PAYMENT_CANCEL_ERROR',
    reason: 'error cancelling payment',
  },
  {
    code: 'INVALID_PAY_ORDER_TYPE',
    reason: 'illegal PayOrder type',
  },
  {
    code: 'ORDER_APPROVAL_FAILED',
    reason: 'error approving order',
  },
  {
    code: 'INVALID_OPERATION_TYPE',
    reason: 'invalid op-type',
  },
  {
    code: 'PAY_ORDER_NOT_FOUND',
    reason: 'Unionfab Pay Order not found',
  },
  {
    code: 'NOT_AN_OCEAN_PAY_ORDER',
    reason: 'Not a Ocean Pay Order',
  },
  {
    code: 'UNKNOWN_OCEAN_PAY_DATA_TYPE',
    reason: 'unknown ocean pay data type',
  },
  {
    code: 'ORDER_RESPONSE_SIGNATURE_MISMATCH',
    reason: 'Check Order Response signature mismatch',
  },
  {
    code: 'ERROR_UPDATING_PAYMENT_TO_EXCEPTION',
    reason: 'error update payment to exception',
  },
];

import * as S from '@unionfab/ufc-shop-commons';
import { i18nFormat } from '@unionfab/ufc-shop-commons';
import { Button, InputNumber, Modal, Space, Typography } from 'antd';
import cn from 'classnames';
import { CSSProperties, useState } from 'react';

import { InsertTable } from '@/commons/components/KnowledgeBase/InsertTable';

import {
  getUseShopQuoteStoreByCode,
  ShopOrderQuoteStoreModifiers,
  useInquiryMaterialStore,
  useShopQuoteUiStore,
} from '../../../../../../../stores';
import styles from './index.module.less';

export interface ShopQuoteItemInsertsProps {
  className?: string;
  style?: CSSProperties;

  orderCode: string;
  itemId: string;
}

export const ShopQuoteItemInserts = ({
  className,
  style,
  orderCode,
  itemId,
}: ShopQuoteItemInsertsProps) => {
  const [modalVisible, toggleVisible] = useState<boolean>(false);
  const { item } = getUseShopQuoteStoreByCode(orderCode)(s => ({
    item: (s.shopOrderDraft.items || []).find(i => i.id === itemId),
  }));

  const { materials } = useInquiryMaterialStore(s => ({
    materials: s.materials,
  }));
  const curTechCategory = useShopQuoteUiStore(s => s.curTechCategory);
  const isCNC = S.get(curTechCategory, c => c.type) === 'CNC';
  const curMaterial = (materials || []).find(
    m => m.id == item?.materialSkuVO?.materialId,
  );
  const curMaterialTypeName = S.get(curMaterial, c => c.materialType.name, '');
  const isSLMMaterial = curMaterialTypeName.toLocaleUpperCase().includes('SLM');

  if (isCNC || isSLMMaterial) return <></>;

  const onChange = (value: number) => {
    ShopOrderQuoteStoreModifiers.updateOrderDraft(orderCode, o => {
      const item = o.items.find(i => i.id === itemId);
      if (item != null) item.nutCount = value;
    });
  };

  return (
    <div className={cn(className, styles.container)} style={style}>
      <div className={styles.shopQuoteItemInsertsTitle}>
        <Typography.Title level={5}>{i18nFormat('Inserts')}</Typography.Title>
      </div>
      <div className={styles.flexStart} style={{ width: '80%' }}>
        <InputNumber onChange={onChange} value={S.get(item, i => i.nutCount)} />
        <Space style={{ marginLeft: '1rem' }} size={12}>
          <span className={styles.desc} onClick={() => toggleVisible(true)}>
            {i18nFormat('Standard Inserts')}
          </span>
          {/* <span className={styles.desc} style={{ cursor: 'unset' }}>
            {i18nFormat('in this part')}
          </span> */}
        </Space>
        <a href=""></a>
      </div>
      <Modal
        width={700}
        footer={false}
        open={modalVisible}
        className={styles.shopQuoteItemInsertsModal}
        onCancel={() => toggleVisible(false)}
      >
        <InsertTable />,
      </Modal>
    </div>
  );
};

ShopQuoteItemInserts.displayName = 'ShopQuoteItemInserts';
